$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  border-top: 1px solid $light-gray;
  border-bottom: 1px solid $light-gray;
}

.toggleButton {
  position: relative;
  padding: 17px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @extend %button-reset;
}

.icon {
  position: absolute;
  right: 0;
  width: 16px;
  height: 16px;
  color: $dove-gray;

  @media screen and (min-width: $breakpoint_m) {
    width: 24px;
    height: 24px;
  }
}

.title {
  color: $black;

  @include text-base-medium;
}
