$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.text {
  margin-bottom: 24px;
  color: $dove-gray;

  @include text-base-regular;
}

.steps {
  margin-bottom: 24px;
  color: $dove-gray;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 13px;

  @media screen and (min-width: $breakpoint_l) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepNumber {
  margin-bottom: 12px;
  width: 48px;
  height: 48px;
  background-color: $harvest-gold;
  border-radius: 50%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-weight: $bold_font_weight;
  color: $black;

  @include font_size(32px);
  @include line_height(29px);
  @include flexCenter;
}

.stepDescription {
  @include text-sm-regular;
}
