$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

div.overlay {
  background: rgba(0, 0, 0, 0.6);
}

div.modal {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  max-width: 100%;
  box-shadow: none;
  overflow: hidden;
  background: $white;

  @media screen and (min-width: $breakpoint_l) {
    margin: 48px 0;
    max-width: 616px;
    height: auto;
  }
}

.modalContent {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.contentWrapper {
  margin: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
}

.title {
  @include text-5xl;

  color: black;
  margin-bottom: 12px;
  text-align: center;
}

.description {
  @include text-lg-regular;

  color: black;
  text-align: center;

  strong {
    @include text-lg-medium;
  }
}

.actions {
  display: flex;
  width: 100%;
  margin-top: 64px;
  justify-self: flex-end;
  align-self: flex-end;
}

.button {
  flex: 1 1 0;
}

.icon {
  color: $black;
  font-size: 88px;
  margin-top: 88px;
  margin-bottom: 40px;

  &.color {
    &-green {
      color: $salem;
    }
  }
}

.closeButton {
  position: absolute;
  top: 24px;
  right: 24px;
  border: none;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  display: flex;

  &:hover {
    opacity: 0.6;
  }

  .closeIcon {
    font-size: 32px;
  }
}
